import { lazy } from "react";
// import GameCards from "../components/GameCards";
const GameCards = lazy(() => import("../components/GameCards"));
import { useEffect, useRef, useState } from "react";
import { getHomeScreen } from "../services/backend-api";
import useGameStore from "../store/useGameStore";
import useSessionStore from "../store/useSessionStore";
import useAdStore from "../store/useAdStore";
import useBannerAdStore from "../store/useBannerAdStore";
import { setupAndroidListeners } from "../utils/androidListeners";
import {
  EventSource,
  EventTypes,
  HOME_PAGE_PARAMS,
  MODALS,
  PopupType,
  WebToApp,
} from "../constants/Constants";
import { AdPlacement } from "../constants/AdConstants";
import usePageStore from "../store/usePageStore";
import { Pages } from "../constants/PageConstants";
import useAdUnitStore from "../store/useAdUnitStore";
// import Backdrop from "../components/Backdrop";
const Backdrop = lazy(() => import("../components/Backdrop"));
import { Game } from "../types/Game";
import { callWebToAppFunction } from "../utils/androidCallers";
import { EventInfo } from "../types/window";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { Action, BrowserHistory, Update, createBrowserHistory } from "history";
import OfflineModal from "../components/Modal/ModalStyles/OfflineModal";
import ErrorModal from "../components/Modal/ModalStyles/OfflineModal copy";
import { document } from "postcss";
import OfferModal from "../components/Modal/ModalStyles/OfferModal";
import img_game1 from "../assets/img_game3.png";
import HeroBanner from "../components/HeroBanner";

const history: BrowserHistory = createBrowserHistory();

export const Home = () => {
  const [homePageParams] = useSearchParams();
  const navigate = useNavigate();
  const gameStore = useGameStore();
  const setHomePageParams = useSessionStore((state) => state.setHomePageParams);
  const setCurrencyData = useSessionStore((state) => state.setCurrencyData);
  const setCurrentGame = useSessionStore((state) => state.setCurrentGame);
  const setWebViewSessionTimeIn = useSessionStore(
    (state) => state.setWebViewSessionTimeIn,
  );
  const [gamesByCategory, setGamesByCategory] = useState<Map<string, Game[]>>();
  const { loadInsAd, loadRdAd, adLoadFailedDueToOffline, adLoadRetryFunc } =
    useAdStore();
  const { setPage } = usePageStore();
  const { setAdUnits } = useAdUnitStore();
  const BUNDLE_ID = import.meta.env.VITE_BUNDLE_ID;
  const { bnAdLoadFailedDueToOffline, loadBNAd, bnAdLoadRetryFunc } =
    useBannerAdStore();
  const [isLoading, setIsLoading] = useState(true);
  const homePageLoadStartTime = useRef(Infinity);

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const retryHandler = useRef<() => boolean>();

  const defaultParam = (key: string, value: string) =>
    import.meta.env.PROD
      ? homePageParams.get(key)
      : homePageParams.get(key) ?? value;

  useEffect(() => {
    if (currentModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [currentModal]);

  useEffect(() => {
    if (adLoadFailedDueToOffline && currentModal !== "OFFLINE") {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.AD_LOAD,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = () => {
        if (window.navigator.onLine) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          adLoadRetryFunc();
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
    }
  }, [adLoadFailedDueToOffline]);

  useEffect(() => {
    if (bnAdLoadFailedDueToOffline && currentModal !== "OFFLINE") {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.AD_LOAD,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = () => {
        if (window.navigator.onLine) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          loadBNAd();
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
    }
  }, [bnAdLoadFailedDueToOffline]);

  useEffect(() => {
    if (bnAdLoadFailedDueToOffline && currentModal !== "OFFLINE") {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.AD_LOAD,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = () => {
        if (window.navigator.onLine) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          bnAdLoadRetryFunc();

          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
    } else if (!bnAdLoadFailedDueToOffline && currentModal === "OFFLINE") {
      setCurrentModal(MODALS.NONE);
    }
  }, [bnAdLoadFailedDueToOffline]);
  useEffect(() => {
    setCurrentGame();
    console.info("*******game fetched**********", gameStore);
    // setTemporaryPlacementFlag(AdPlacement.game_start_fsi);
    homePageLoadStartTime.current = Infinity;
    console.log(navigator.userAgent);
    if (
      import.meta.env.MODE !== "development" &&
      !navigator.userAgent.includes("Version/")
    ) {
      navigate("/oops");
    }
    if (!gameStore.isGamesFetched) {
      // console.time("startloadinghomescreen");
      getCards();
      homePageLoadStartTime.current = performance.now();
      setWebViewSessionTimeIn(homePageLoadStartTime.current);
      // console.log("i am here start", performance.now());
    } else {
      /**calling ins ad load only once for the fist time and not the subsequent times */
      // loadInsAd(AdPlacement?.game_start_fsi);
      // loadInsAd(AdPlacement?.game_exit_fsi);
      // loadInsAd(AdPlacement?.game_replay_fsi);
      // loadInsAd(AdPlacement?.game_end_fsi);
      // loadInsAd(AdPlacement?.game_pause_fsi);

      setIsLoading(false);
    }
  }, []);
  const getCards = () => {
    console.info("making api call");
    const userIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.userId,
      "c7805784-7dfe-4213-a1b2-69702099bdf6",
    );
    const bundleIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.bundleId,
      BUNDLE_ID,
    );
    const thirdPartyIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.thirdPartyId,
      "12345654321",
    );
    const ipAddressFromParam = defaultParam(
      HOME_PAGE_PARAMS.ipAddress,
      "76.167.170.156",
    );
    const accountIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.accountId,
      "3b9d56c9-1e9c-44f3-8240-6d1cd2a9c53e",
    );
    const webviewSessionIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.webViewSessionId,
      "",
    );
    if (!window.navigator.onLine) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = () => {
        if (window.navigator.onLine) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          getCards();
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
    } else {
      //setUserId(userIdFromParam);
      console.log("userIdFromParam:", userIdFromParam);
      console.log("bundleIdFromParam:", bundleIdFromParam);
      console.log("thirdPartyIdFromParam:", thirdPartyIdFromParam);
      console.log("ipAddressFromParam:", ipAddressFromParam);
      console.log("accountIdFromParam:", accountIdFromParam);
      console.log("webviewSessionIdFromParam:", webviewSessionIdFromParam);
      setHomePageParams({
        accountId: accountIdFromParam!,
        bundleId: bundleIdFromParam!,
        ipAddress: ipAddressFromParam!,
        thirdPartyId: thirdPartyIdFromParam!,
        userId: userIdFromParam!,
        webviewSessionId: webviewSessionIdFromParam!,
      });
      setIsLoading(true);
      getHomeScreen({
        bundleId: bundleIdFromParam!,
        thirdPartyId: thirdPartyIdFromParam!,
        lastLogin: new Date().toISOString(),
        ipAddress: ipAddressFromParam!,
        accountId: accountIdFromParam!,
        webviewSessionId: webviewSessionIdFromParam!,
        userId: userIdFromParam!,
      })
        .then((res) => {
          // console.log("i am here end", performance.now());
          // console.timeEnd("startloadinghomescreen");
          // setIsLoading(false);
          //console.log("res", res.data);
          // we are getting user id now from the initial call params
          //const userId = res.data.userId;
          // setUserId(userId);
          const currencyData = res.data.currencyData;
          setCurrencyData(currencyData);
          const categoryRanking = res?.data?.categoryRankings;
          const gamesList = res?.data?.gamesMap;
          // console.log("categoryRanking", categoryRanking);
          console.log("gamesList", gamesList);
          const gameListByRanking = new Map();
          categoryRanking.forEach((category) => {
            gameListByRanking.set(category, gamesList[category]);
          });
          // console.log("gameListByRanking", gameListByRanking);
          gameStore.setCategoryRanking(categoryRanking);
          gameStore.setGames(gamesList);
          const adUnits = res.data.adUnits;
          setAdUnits(adUnits);
          const homePageLoadedEvent: EventInfo = {
            eventType: EventTypes.first_load_success,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: window.navigator.onLine ? "online" : "offline",
              url: window.location.href,
              page_type: "Homepage",
              first_loadtime: performance.now(),
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            homePageLoadedEvent,
            null,
            undefined,
          );
          // TODO: Load all ads after SDK fix in the new version
          // loading banner ads
          loadInsAd(AdPlacement?.game_start_fsi);
          console.log("HomePage:game_start_fsi");
          loadInsAd(AdPlacement?.game_exit_fsi);
          console.log("HomePage:game_exit_fsi");
          loadRdAd(AdPlacement?.game_play_fsr);
          console.log("HomePage:game_play_fsr");
          loadInsAd(AdPlacement?.game_replay_fsi);
          console.log("HomePage:game_replay_fsi");
          loadInsAd(AdPlacement?.game_end_fsi);
          console.log("HomePage:game_end_fsi");
          loadInsAd(AdPlacement?.game_pause_fsi);
          console.log("HomePage:game_pause_fsi");
          loadBNAd();
          setIsLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          // console.log("error");
          retryHandler.current = () => {
            if (window.navigator.onLine) {
              console.log(
                "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              getCards();
              return true;
            }
            return false;
          };
          setCurrentModal(MODALS.UNEXPECTED_ERROR);
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setPage(Pages.HOME);
    //return window.removeEventListener("load", onPageFullyLoaded);
  }, []);

  useEffect(() => {
    history.push("/");
    const unlisten = history.listen(backButtonEvent);

    //logic for showing popup warning on page refresh
    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backButtonEvent = ({ action }: Update) => {
    // console.log("home back");
    if (action === Action.Pop) {
      // console.log("I am here onBackButtonEvent");
      // //send analytics to andorid before closeing
      // console.log(
      //   "webview_close analytics event webview session time:",
      //   useSessionStore.getState().webViewSessionTimeIn - performance.now()
      // );
      const homePageLoadedEvent: EventInfo = {
        eventType: EventTypes.webview_close,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          webview_session_time:
            performance.now() - useSessionStore.getState().webViewSessionTimeIn,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        homePageLoadedEvent,
        null,
        undefined,
      );
      window.Android.closeWebview();
    }
  };

  useEffect(() => {
    // Set up Android listeners
    const cleanup = setupAndroidListeners();
    console.log("adding event listener");
    return cleanup; // Cleanup listeners when the component unmounts
  }, []);
  useEffect(() => {
    const categoryRanking = gameStore.categoryRanking;
    const allGames = gameStore.games;
    const gamesByCategory = new Map();
    categoryRanking.forEach((category) => {
      gamesByCategory.set(category, allGames[category]);
    });
    setGamesByCategory(gamesByCategory);
  }, [gameStore.categoryRanking, gameStore.games]);

  if (isLoading) {
    return (
      <Backdrop show={true}>
        <div className="spinner"></div>
      </Backdrop>
    );
  }
  return (
    <>
      <main className="flex-grow mt-12">
        <div className="mx-auto w-full max-w-screen-xl">
          <HeroBanner />
          {Array.from(gamesByCategory?.entries()!).map(
            ([category, games], idx) => {
              return (
                <GameCards
                  key={idx}
                  category={category}
                  games={games}
                  setIsLoading={(flag: boolean) => {
                    setIsLoading(flag);
                  }}
                />
              );
            },
          )}
        </div>
      </main>
      <footer />
      <div
        className="flex justify-center items-center"
        // style={{ height: `${adHeight}px` }}
        style={{ height: `${(50 / 320) * 100}vw` }}
      ></div>
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={() => {
            if (retryHandler.current && retryHandler.current()) {
              setCurrentModal(MODALS.NONE);
            }
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={() => {
            if (retryHandler.current && retryHandler.current()) {
              setCurrentModal(MODALS.NONE);
            }
          }}
          exitShow
        ></ErrorModal>
      )}
      {currentModal == "OFFER" && (
        <OfferModal
          open={currentModal == "OFFER"}
          imageUrl={img_game1}
          onCloseClickedHandler={() => setCurrentModal(MODALS.NONE)}
        />
      )}
    </>
  );
};
